.section__map {
  padding: 2rem 0 0 0;
}

#states {
  fill: #00c3ff;
  stroke: #fff;
  stroke-width: 1.25px;
}

.graticuleLine {
  fill: none;
  stroke: #777;
  stroke-width: 0.5px;
}

.feature {
  opacity: 0.75;
}

.feature:not(.active) {
  cursor: zoom-in;
}

.feature.active {
  cursor: zoom-out;
  fill: #a32f88;
}

.feature.hovered {
  opacity: 1;
  cursor: zoom-in;
}

.pin {
  fill: #a32f88;
  cursor: pointer;
}

.map_title {
  text-align: center;
  margin: 0 0 2rem 0;
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1;
  color: rgba(44, 54, 79, 0.5);
}
