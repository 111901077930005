html {
  font-size: 16px;
}

* {
  font-family: 'Helvetica';
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 960px) {
  html {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  html {
    font-size: 13px;
  }
}

body {
  background: #fff !important;
  font-size: 1rem;
}

body * {
  color: #2c364f;
}

p *,
span * {
  color: inherit;
}

a {
  text-decoration: none;
}

/* landing page */

.landing_buttons-wrapper {
  margin: -0.5rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.landing_buttons-wrapper button {
  margin: 0.5rem;
  width: 15rem;
}

.landing_buttons-wrapper-std {
  margin: 0.5rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.landing_buttons-wrapper-std button {
  margin: 0.5rem;
  width: 15rem;
}

.landing_top-section {
  background: rgb(0, 195, 255);
  background-image: linear-gradient(60deg, rgba(0, 195, 255, 1) 0%, rgba(163, 47, 136, 0.5) 100%), url('./media/landing/landing-header.jpg');
  background-size: cover;
  background-position: 75% center;
  background-repeat: no-repeat;
  position: relative;
  /* min-height: 100vh;
  height: 100%; */
  /* padding-top: 16vh; */
  /* padding: 8rem 0 14rem 0; */
}

/* @media (max-width: 900px) and (orientation: landscape) {
  .landing_top-section {
    min-height: 70vw;
  }
} */

.landing_call-to-action {
  transform: translateY(-40%);
  text-align: center;
  margin: 0 auto;
}

.landing_call-to-action__bottom {
  text-align: center;
  margin: 0 auto;
}

.landing_box,
.landing_box__padding {
  box-shadow: 0px 3px 26px rgba(0, 0, 0, 0.16);
  background: #fff;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}

.landing_box__padding {
  padding: 3rem;
}

.landing_second-section {
  position: relative;
  background: #e5f6ff;
  padding: 0 0 5rem 0;
}

.landing_text__light {
  color: #fff;
  fill: #fff;
}

.landing_text__blue {
  color: #00679e;
  fill: #00679e;
}

.landing_third-section {
  background: #4dd5ff;
  padding: 5rem 0;
}

.landing_fourth-section {
  position: relative;
  background: rgb(163,47,136);
  background: linear-gradient(180deg, rgba(163,47,136,1) 0%, rgba(0,195,255,1) 100%);
  padding: 15rem 0 5rem;
}

.landing_fourth-section__bg-img {
  position: absolute;
  max-width: 350px;
  width: 100%;
  top: 20px;
  left: -20px;
}

.landing_fifth-section {
  /* background: #e5f6ff; */
  padding: 5rem 0;
}

.landing_img {
  object-fit: cover;
  min-width: 100%;
  height: 100%;
}

.landing_img_gradient {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 195, 255, 0.6) 0%, rgba(163, 47, 136, 0.6) 100%);
}

.landing_img_gradient--h15rem {
  height: 15rem;
}

.landing_img-wrapper {
  width: 100%;
  height: 15rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing_img-wrapper__box {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing_img-wrapper__box img {
  object-fit: cover;
  min-width: 100%;
  height: 100%;
}

.landing_sixth-section {
  padding: 5rem 0;
}

.landing_seventh-section {
  padding: 5rem 0;
  position: relative;
  background-image: url('./media/landing/section-bg-7.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.landing_footer {
  padding: 5rem 0 3rem;
  background-image: url('./media/landing/footer-bg.svg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* landing page ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ */

/* scrollbar */

::-webkit-scrollbar {
  width: 5px;
  border-radius: 2.5px;
}

::-webkit-scrollbar-track {
  background: rgba(44, 54, 79, 0.1);
  border-radius: 2.5px;
}

::-webkit-scrollbar-thumb {
  background: rgba(44, 54, 79, 0.3);
  border-radius: 0;
}

/* buttons */

.icon-button-wrapper {
  width: 1rem;
  height: 1rem;
}

.icon-button-wrapper * {
  min-width: 100%;
  height: auto;
}

.icon-button__dark {
  /* fill: #2c364f !important; */
  fill: #a32f88 !important;
  transition: all 0.3s ease 0s;
}

.icon-button__dark.search_input {
  fill: #2c364f !important;
}

.icon-button__dark:hover {
  fill: rgba(44, 54, 79, 0.5) !important;
  cursor: pointer;
}

.icon-button__light {
  fill: #fff;
  transition: all 0.3s ease 0s;
}

.icon-button__light:hover {
  fill: #d1d8e1;
  cursor: pointer;
}

.icon-button__default {
  fill: rgba(44, 54, 79, 0.5);
  transition: all 0.3s ease 0s;
}

.icon-button__default:hover {
  fill: rgba(44, 54, 79, 0.7);
  cursor: pointer;
}

.action-button__default,
.action-button__default * {
  /* color: #2c364f; */
  /* fill: #2c364f; */
  color: #a32f88;
  fill: #a32f88;
  transition: all 0.3s ease 0s;
}

.action-button__default:hover,
.action-button__default:hover * {
  color: rgba(44, 54, 79, 0.5);
  fill: rgba(44, 54, 79, 0.5);
  cursor: pointer;
  transition: all 0.3s ease 0s;
}

.action-button__light,
.action-button__light * {
  color: #fff;
  fill: #fff;
  transition: all 0.3s ease 0s;
}

.action-button__light:hover,
.action-button__light:hover * {
  color: rgba(255, 255, 255, 0.75);
  fill: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  transition: all 0.3s ease 0s;
}

.icon-button__primary,
.action-button__primary,
.action-button__primary * {
  font-weight: bold;
  color: #a32f88;
  fill: #a32f88;
  transition: all 0.3s ease 0s;
  max-width: 100%;
  max-height: 100%;
}

.icon-button__primary:hover,
.action-button__primary:hover,
.action-button__primary:hover * {
  color: #a32f88b3;
  fill: #a32f88b3;
  cursor: pointer;
  transition: all 0.3s ease 0s;
}

.see-all-button {
  margin-left: 1rem;
  transition: all 0.3s ease 0s;
  text-align: end;
  white-space: nowrap;
}

/* nav */

.nav_link {
  font-weight: bold;
  font-size: 1rem;
  transition: all 0.3s ease 0s;
}

.nav_link:hover {
  color: rgba(44, 54, 79, 0.5);
}

/* beadcrumbs */

.breadcrumbs-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0.825rem 0;
}

.breadcrumb-button {
  display: inline-flex;
  align-items: center;
}

.breadcrumb-button,
.breadcrumb-button * {
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: bold;
  color: rgba(44, 54, 79, 0.5);
  fill: rgba(44, 54, 79, 0.5);
  transition: all 0.3s ease 0s;
}

.breadcrumb-button * {
  /* margin-right: 0.5rem; */
}

.breadcrumb-button {
  /* margin-right: 1rem; */
}

.breadcrumb-button:hover,
.breadcrumb-button:hover * {
  color: #2c364f;
  fill: #2c364f;
  cursor: pointer;
}

/* sections */

.page-404 {
  min-height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section__light,
.section__dark,
.section__pagination {
  padding: 2rem 0;
  transition: all 0.3s ease 0s;
}

.section__pagination {
  height: 100%;
  min-height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.section__dark {
  background: rgba(229, 229, 229, 0.2);
}

.section__dark_new {
  background-color: #E5F6FE;
}


.section_title-wrapper {
  margin: 0 0 2rem 0;
  display: flex;
  align-items: center;
}

.section_title {
  margin: 0 auto;
  display: inline-block;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1rem;
  text-transform: uppercase;
  color: rgba(44, 54, 79, 0.5);
}

.section_title_new {
  font-size: 1.4rem;
  line-height: 1.4rem;
  font-family: "Noto Serif SC";
  text-transform: capitalize;
}

.section_title_new_large {
  margin-top: 2rem;
  font-size: 2rem;
  line-height: 3rem;
  font-family: "Noto Serif SC";
  /* font-weight: 500; */
  text-transform: none;
  text-align: center;
}

/* auth / profile */

.auth-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: calc(100vh - 9rem);
}

.auth_text-main {
  font-size: 1rem;
  line-height: 1.25rem;
}

.auth_text-bold {
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.auth_text-secondary {
  color: rgba(44, 54, 79, 0.5);
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-bottom: 3.5rem;
}

.auth_link {
  font-weight: bold;
  font-size: inherit;
  line-height: inherit;
  text-decoration: underline;
  color: #a32f88;
  transition: all 0.3s ease 0s;
}

.auth_link:hover {
  color: #a32f88b3;
  cursor: pointer;
}

.auth_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem auto;
}

.auth_helper-button,
.auth_helper-text {
  width: 100%;
  text-align: right;
  color: rgba(44, 54, 79, 0.5);
  font-size: 0.875rem;
  line-height: 1.25rem;
  transition: all 0.3s ease 0s;
}

.auth_helper-button:hover {
  color: #2c364f;
  cursor: pointer;
}

.auth_checkbox-label {
  font-weight: 0.875rem;
  line-height: 1.25rem;
}

.auth_checkbox-wrapper {
  width: 100%;
  margin-top: 1.5rem;
  padding-left: 2px;
}

.auth_social-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 2rem auto;
}

.auth_social-button {
  fill: rgba(44, 54, 79, 0.5);
  transition: all 0.3s ease 0s;
  margin: 0 0.75rem;
}

.auth_social-button:hover {
  cursor: pointer;
  fill: #a32f88;
}

.profile_title {
  font-size: 2rem;
  line-height: 2.5rem;
  margin: 2rem auto 1.25rem auto;
}

.profile_subtitle {
  width: 100%;
  text-align: left;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1rem;
  margin: 0 0 1.5rem 0;
}

/* hub card */

.card_gradient {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 195, 255, 0.6) 0%, rgba(163, 47, 136, 0.6) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.play-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.4);
}

.play-icon-wrapper:hover {
  opacity: 0.7;
}

.hub_teaser_card_text {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* article card */

.teaser-video {
  flex: 1;
}

.teaser-wrapper {
  height: 100%;
  flex: 3;
}

.teaser-wrapper:hover {
  cursor: pointer;
}

.teaser-wrapper:hover .teaser_button {
  opacity: 1;
}

.teaser-wrapper:hover .teaser_button {
  opacity: 1;
}

.teaser_info-wrapper,
.teaser_label-wrapper {
  display: flex;
  align-items: center;
}

.teaser_info-wrapper {
  margin: 0.75rem 0 0 0;
}
.teaser_label-wrapper {
  margin: 0;
}

.teaser__text-wrapper {
  max-width: 100%;
  overflow-wrap: break-word;
}

.teaser__text-wrapper * {
  font-size: 0.75rem;
  overflow-wrap: break-word;
}

.teaser_label,
.teaser_text-secondary {
  font-size: 0.75rem;
}

.teaser_text-secondary {
  color: rgba(44, 54, 79, 0.5);
}

.teaser_title, .teaser_title * {
  margin: 0 0 0.1rem 0;
  font-size: 1.125rem;
  font-weight: bold;
  color: #13668F;
}

.teaser_ads {
  margin: 0 0 0.5rem 0;
}

.teaser_title-wrapper {
  margin-bottom: 0.5rem;
}

.teaser_text {
  font-size: 0.875rem;
  margin: 0;
  white-space: pre-line;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  margin-block-start: 0.1em;
  margin-block-end: 0.1em;
}

.teaser_button {
  color: #2c364f;
  fill: #2c364f;
  /* font-size: 0.75rem;
  vertical-align: middle; */
}

.teaser_button {
  opacity: 1;
  transition: all 0.3s ease 0s;
}

.teaser_button:hover {
  color: rgba(44, 54, 79, 0.5);
  fill: rgba(44, 54, 79, 0.5);
  cursor: pointer;
}

.teaser_button * {
  fill: inherit;
  /* height: 0.75rem;
  width: 0.75rem;
  vertical-align: middle; */
}

.articles-scroll-wrapper {
  padding: 2rem;
  height: 100%;
  overflow: auto;
}

/* document */
.document {}

.document__header {
  position: relative;
  width: 100%;
  height: 400px;
  margin-bottom: -200px;
  z-index: -1;
}

.document__header::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 195, 255, 0.6);
  z-index: 1;
}

.document__header_title {
  font-family: 'Noto Serif SC';
  position: absolute;
  left: 50%;
  margin: 0;
  font-size: 2rem;
  text-align: center;
  color: white;
  z-index: 2;
}

.document__header_pic {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


/* article */
.content-wrapper {
  word-break: break-word;
}

.content-wrapper p,
.content-wrapper pre {
  font-size: 1.25rem;
  line-height: 2rem;
  margin: 1rem 0;
  white-space: pre-line;
}

.content-headline {
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 2rem;
  margin: 1rem 0;
  white-space: pre-line;
}

.content-wrapper ul {
  list-style: url('./media/icons/list-bullet.svg');
  padding-left: 1rem;
}

.content-wrapper li {
  padding-left: 0.625rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.content-wrapper h1,
.content-wrapper h2,
.content-wrapper h3,
.content-wrapper h4,
.content-wrapper h5,
.content-wrapper h6 {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 1.5rem 0;
}

.content-wrapper table {
  width: 100%;
  border: 1px solid #000;
  border-collapse: collapse;
}

.content-wrapper th,
.content-wrapper td {
  padding: 0.25rem 1rem;
  border: 1px solid #000;
}

.content-wrapper a {
  display: inline !important;
  font-weight: bold;
  font-size: inherit;
  line-height: inherit;
  color: rgba(44, 54, 79, 0.5);
  text-decoration: underline;
}

.content-wrapper a:hover {
  color: #a32f88b3;
  cursor: pointer;
}

.article_info-wrapper,
.article_label-wrapper {
  display: flex;
  align-items: center;
  margin: 1rem 0;
  white-space: nowrap;
}

.article_label-wrapper.doc,
.article_info-wrapper.doc {
  margin: 0;
}

.article_label.doc {
  font-weight: bold;
  white-space: normal;
}

.article_label,
.article_text-secondary {
  font-size: 1rem;
  display: inline-block;
}

.article_text-secondary {
  color: rgba(44, 54, 79, 0.5);
}
.article_text-secondary.doc {
  line-height: 140%;
}

.article_title, .article_title * {
  font-family: 'Noto Serif SC';
  font-size: 3rem;
  line-height: 117%;
  margin: 0 0 38px 0;
  color: #13668F;
}

.article_subtitle {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 1.5rem 0;
}

.article_text {
  font-size: 1.25rem;
  line-height: 2rem;
  margin: 1rem 0;
  white-space: pre-line;
}

.article_image {
  display: block;
  width: 100%;
  aspect-ratio: 1 / 1;
  max-width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.article_button {
  color: #2c364f;
  fill: #2c364f;
  font-size: 1rem;
  vertical-align: middle;
}

.article_button:hover {
  color: rgba(44, 54, 79, 0.5);
  fill: rgba(44, 54, 79, 0.5);
  cursor: pointer;
}

.article_button * {
  fill: inherit;
  height: 1rem;
  width: 1rem;
  vertical-align: middle;
}

.article_link-wrapper {
  margin: 1rem 0;
}

.article_link {
  font-weight: bold;
  font-size: 1rem;
  line-height: 2rem;
  color: rgba(44, 54, 79, 0.5);
}

.article_link:hover {
  color: #2c364f;
  cursor: pointer;
}

.article_list {
  list-style: url('./media/icons/list-bullet.svg');
  padding-left: 1rem;
}

.article_list-item {
  padding-left: 0.625rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

/* chip */

.chip-icon-wrapper {
  width: 2.5rem;
}

.chip-icon {
  margin: 0 0.75rem 0 1rem !important;
  width: 0.75rem !important;
  max-height: 0.75rem !important;
  min-width: 0.75rem !important;
}

/* footer */

.footer {
  top: auto;
  bottom: 0;
}

.footer * {
  font-size: 1rem;
}

/* page */

.page-title-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-title {
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 2.5rem;
  margin: 1rem auto;
  text-align: center;
}

.page_title_new {
  margin-top: 2rem;
  font-size: 2rem;
  line-height: 3rem;
  font-family: "Noto Serif SC";
  /* font-weight: 500; */
  text-transform: none;
  text-align: center;
}

.home-page-button {
  color: rgba(44, 54, 79, 0.5);
  fill: rgba(44, 54, 79, 0.5);
  font-size: 0.875rem;
  margin: 0 auto 1rem;
  transition: all 0.3s ease 0s;
}

.home-page-button:hover {
  color: rgba(44, 54, 79, 1);
  fill: rgba(44, 54, 79, 1);
  cursor: pointer;
}

.home-page-button:active {
  color: rgba(44, 54, 79, 0.7);
  fill: rgba(44, 54, 79, 0.7);
  cursor: pointer;
}

.home-page-button * {
  color: inherit;
  fill: inherit;
  width: 0.625rem;
  height: 0.625rem;
}

.grid-button {
  margin-left: 1rem;
  fill: #2c364f;
  opacity: 0.5;
  transition: all 0.3s ease 0s;
  cursor: pointer;
}

.grid-button:hover {
  opacity: 1;
}

.grid-button__selected {
  opacity: 1;
}

.pagination-wrapper {
  display: flex;
  justify-content: center;
  margin: 2rem auto 0;
}

.no-results-msg {
  display: block;
  font-size: 1rem;
  color: rgba(44, 54, 79, 0.5);
  margin: 1.5rem auto;
  text-align: center;
}

/* tabs */

.tab-wrapper {
  display: flex;
  align-items: center;
  padding-right: 2rem;
  white-space: nowrap;
}

.tab-wrapper * {
  white-space: nowrap;
}

.tab-icon-wrapper {
  width: 14px;
  max-height: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.tab-icon-wrapper * {
  fill: #2c364f;
  max-width: 100%;
  max-height: 100%;
}

/* filter */

.filter_section-title {
  text-transform: uppercase;
  margin-bottom: 1rem;
  color: #2c364f !important;
  opacity: 1;
  white-space: nowrap;
}

.filter_option-title {
  white-space: nowrap;
}

.filter_section-label {
  font-weight: 500;
  margin: 0 1rem 1rem 0;
}

.filter_option-label {
  font-weight: 300;
  margin: 0 1rem 1rem 0;
  white-space: nowrap;
}

/* media player */

.youtube-container {
  flex: 2;
  border-radius: 5px;
  overflow: hidden;
}
.youtube-iframe {
  width: 100%;
  aspect-ratio: 16 / 9;
}

video {
  display: block;
}

video::-webkit-media-controls {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
  -webkit-appearance: none !important;
}

.video_gradient {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 5px;
  background: linear-gradient(180deg, rgba(44, 54, 79, 0.1) 0%, rgba(44, 54, 79, 0.5) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.play-icon-wrapper:hover {
  opacity: 0.8;
  cursor: pointer;
}

.hide {
  display: none;
}

#video-player {
  position: relative;
  width: 100%;
  height: auto;
  display: block;
  border-radius: 5px;
  margin-inline-start: 0;
  margin-inline-end: 0;
  display: flex;
  align-items: center;
}

#audio-player {
  width: 100%;
  min-height: 50px;
  margin-top: 1rem;
  background: #3f4452;
  border-radius: 5px;
  overflow: hidden;
}

#video-controls {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  padding: 0;
  /* visibility: hidden;
  opacity: 0; */
  transition: all 0.3s ease-out 0s;
  /* z-index: 2147483647; */
  display: flex;
  flex-direction: column;
}

#video-player:hover > #video-controls {
  /* visibility: visible; */
  opacity: 1;
  border-radius: 5px;
  /* overflow: hidden; */
}

#video-player video {
  border-radius: 5px;
  overflow: hidden;
}

.control-btn {
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-out 0s;
  background: none;
  margin: 0 7px;
}

.control-btn:hover * {
  opacity: 0.8;
}

.control-btn * {
  fill: #fff;
  /* width: 25px;
  height: 25px; */
  transition: all 0.3s ease-out 0s;
}

.media-time {
  color: #fff;
  font-size: 1rem;
  margin: 0 0.5rem;
  white-space: nowrap;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  #video-player input[type='range'].video_progress {
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    margin: 0;
    transition: background 450ms ease-in;
  }

  #video-player input[type='range'].video_progress:focus {
    outline: none;
  }

  #video-player input[type='range'].video_progress::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    cursor: pointer;
    border-radius: 0;
    border: 0px solid rgba(1, 1, 1, 0);
    transition: all 0.3s ease-out 0s;
  }

  #video-player input[type='range'].video_progress::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
    border: 0px solid rgba(0, 0, 0, 0);
    height: 10px;
    width: 10px;
    border-radius: 5px;
    background: #fff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -2.5px;
    visibility: hidden;
    opacity: 0;
  }

  #video-player input[type='range'].video_progress:hover::-webkit-slider-thumb {
    visibility: visible;
    opacity: 1;
  }

  #audio-player input[type='range'] {
    -webkit-appearance: none;
    width: 100%;
    height: 2px;
    margin: 0;
    transition: background 450ms ease-in;
  }

  #audio-player input[type='range']:focus {
    outline: none;
  }

  #audio-player input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    border-radius: 0;
    border: 0px solid rgba(1, 1, 1, 0);
    transition: all 0.3s ease-out 0s;
  }

  #audio-player input[type='range']::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
    border: 0px solid rgba(0, 0, 0, 0);
    height: 10px;
    width: 10px;
    border-radius: 5px;
    background: #fff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -4px;
  }

  /* input[type='range'].video_progress:focus::-webkit-slider-runnable-track {
    background: #d1d8e1;
  } */
}

.video_volume-progress {
  transition: all 0.3s ease-out 0s;
}

.video_volume-wrapper {
  width: 150px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 15px;
  margin-right: 50px;
}

.video_buttons-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 0.5rem;
}

#video-player input[type='range']::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  border-radius: 0;
  border: 0px solid rgba(1, 1, 1, 0);
}

#video-player input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  border: 0px solid rgba(0, 0, 0, 0);
  height: 12px;
  width: 12px;
  border-radius: 6px;
  background: #fff;
  cursor: pointer;
  margin-top: -5px;
}

figure[data-fullscreen='true'] {
  max-width: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

figure[data-fullscreen='true'] video {
  height: auto;
  border-radius: 0 !important;
}

figure[data-fullscreen='true'] .video_gradient {
  border-radius: 0 !important;
}

figure[data-fullscreen='true'] #video-controls {
  bottom: 2%;
}

#audio-player input[type='range']::-moz-range-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  border-radius: 0;
  border: 0px solid rgba(1, 1, 1, 0);
}

#audio-player input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  border: 0px solid rgba(0, 0, 0, 0);
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background: #fff;
  cursor: pointer;
  margin-top: -4px;
}

/* input[type='range'].video_progress::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type='range'].video_progress::-ms-fill-lower {
  background: red;
  border: 0px solid rgba(1, 1, 1, 0);
  border-radius: 0;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
}

input[type='range'].video_progress::-ms-fill-upper {
  background: red;
  border: 0px solid rgba(1, 1, 1, 0);
  border-radius: 3.8px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
}

input[type='range'].video_progress::-ms-thumb {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  border: 0px solid rgba(0, 0, 0, 0);
  height: 5px;
  width: 5px;
  border-radius: 0;
  background: #eeeeee;
  cursor: pointer;
}

input[type='range'].video_progress:focus::-ms-fill-lower {
  background: red;
}

input[type='range'].video_progress:focus::-ms-fill-upper {
  background: red;
} */

/* media */

@media (max-width: 960px) {
  /* article */

  .article_title {
    font-size: 2rem;
  }

  /* page */

  .page-title {
    font-size: 2rem;
    line-height: 2rem;
  }

  /* teaser */

  .teaser_button {
    opacity: 1;
  }
}

@media (max-width: 600px) {
  /* footer */

  .footer * {
    font-size: 0.625rem;
  }

  /* page */

  .page-title {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }

  /* auth */

  .auth-wrapper {
    min-height: calc(100vh - 7.5rem);
  }

  /* tabs */

  .tab-wrapper {
    padding-right: 0;
  }

  .tab-icon-wrapper {
    justify-content: center;
  }

  /* article */

  .article_info-wrapper,
  .article_label-wrapper {
    margin: 0.75rem 0;
    white-space: nowrap;
  }

  .article_label,
  .article_text-secondary {
    font-size: 0.875rem;
    display: inline-block;
    white-space: nowrap;
  }

  .article_title {
    font-size: 1.5rem;
    margin: 0 0 1.5rem 0;
  }

  .article_subtitle {
    font-size: 1rem;
    margin: 1rem 0;
  }

  .article_text {
    font-size: 1rem;
  }

  .article_button {
    font-size: 0.875rem;
    white-space: nowrap;
  }

  .article_button * {
    height: 0.875rem;
    width: 0.875rem;
  }

  .article_list-item {
    font-size: 1rem;
  }

  .article_link {
    font-size: 0.875rem;
  }

  /* breadcrumbs */


.breadcrumbs-wrapper {
    justify-content: center;
  }
}

.breadcrumbs_doc_bottom_wrapper {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.teaser-overlay {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%; /* Adjust the width as needed */
  max-width: 600px; /* Set a maximum width */
  background-color: #dad5d5; /* Solid background */
  color: white;
  padding: 10px;
  z-index: 1000;
  border-radius: 8px; /* Optional: Add rounded corners */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Optional: Adds a subtle shadow */
  display: none; /* Initially hidden */
}

.teaser-overlay .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: black;
  font-size: 1.5rem;
  cursor: pointer;
}